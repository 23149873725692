import React from "react"
import { Link } from "gatsby"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { FaRegLightbulb } from "react-icons/fa"
import { BiBuildings } from "react-icons/bi"
import { IconContext } from "react-icons"
import styled, { css } from "styled-components/"

import { media } from "../styles/media"
import { pagePath, externalLinkPath } from "../constants/link"

import HeaderNonVisual from "../components/HeaderNonVisual"
import InquiryForm from "../components/InquiryForm"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const StyledToastContainer = styled(ToastContainer).attrs({})`
  .Toastify__toast-body {
    color: #fff;
  }
`

const InquiryWrap = styled.div`
  width: 80%;
  max-width: 1000px;
  margin: 0px auto;
  ${() =>
    media.sp(css`
      width: 90%;
    `)}
`

const notify = {
  success: message => {
    toast.success(message)
  },
  error: message => {
    toast.error(message)
  },
}

const LinksBeforeForm = styled.div`
  padding: 50px 20% 20px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  a {
    padding: 0.62em 3.62em;
    border-radius: 10px;
    text-align: center;
    color: white;
    line-height: 1em;
    font-size: 0.9em;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    svg {
      margin-bottom: 0.32em;
    }
    :nth-child(1) {
      background: ${({ theme }) => theme.colors.primary.red};
    }
    :nth-child(2) {
      background: ${({ theme }) => theme.colors.primary.green};
    }
  }
  ${() =>
    media.sp(css`
      padding: 20px 5%;
      a {
        flex-flow: row nowrap;
        margin-bottom: 10px;
        svg {
          margin: 0 0.32em;
        }
      }
    `)}
`

const Inquiry = () => {
  return (
    <Layout>
      <SEO>
        <title>商品に関するお問合せ｜フランソア</title>
        <meta
          name="description"
          content="フランソアの商品に関するお問合せ窓口。"
        />
      </SEO>
      <StyledToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <HeaderNonVisual
        textEn="INQUIRY"
        textJp="商品に関するお問合せ"
        textMain="フランソアは、お客さまからいただいた声を
商品やサービスの改良に生かします。ぜひお声をお聞かせください。"
        textDetails="<ul><li>こちらは商品に関するお問合せフォームです。商品以外のお問合せについては、お手数ですが下記「会社に関するお問合せ」フォームからお問合せください。</li><li>お問い合わせの受付確認について<br>以下のフォーム送信後に自動返信メールをお送りいたします。<br>※１日経過しても届かない場合は、お手数ですがその旨をお問い合わせください。<br>（フリーダイヤル：0120-894-180）</li><li>お問い合わせ内容へのご返信について</br>通常７日以内には返信いたします。<br>※メールアドレスに誤りがある場合、またシステム障害の際には、ご返答できない場合もございます。</li><li>返信が届かない場合について<br>７日経過しても返信がない場合は、お手数ですが再度フォームにてお問い合わせください。</li><li>返信内容の取り扱いについてのお願い<br>弊社からの返答はお客様個人宛てにお送りするものです。<br>返答の一部または全体の転載・二次利用はお断りいたします。</li><li>セールス、勧誘等について<br>こちらのお問い合わせフォームからのセールス、勧誘等は固くお断りいたします。<br>（送信いただいても対応いたしかねます）</li></ul>"
      />

      <LinksBeforeForm>
        <Link to={pagePath.faq.path}>
          <IconContext.Provider value={{ color: "white", size: "1.62em" }}>
            <FaRegLightbulb />
          </IconContext.Provider>
          お問合せの前に
          <br />
          〜よくあるご質問
        </Link>
        <a
          href={externalLinkPath.corporateInquiry.path}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconContext.Provider value={{ color: "white", size: "1.62em" }}>
            <BiBuildings />
          </IconContext.Provider>
          会社に関する
          <br />
          お問合せはこちら
        </a>
      </LinksBeforeForm>

      <InquiryWrap>
        <InquiryForm notify={notify} />
      </InquiryWrap>
    </Layout>
  )
}
export default Inquiry
